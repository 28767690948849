import { CalendarMonthOutlined as CalendarMonthIcon } from "@mui/icons-material";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { useMemo, useState } from "react";
import {
  PickersDay,
  pickersDayClasses,
  PickersDayProps,
  StaticDatePicker,
} from "@mui/x-date-pickers";
import { Language, SafeAreas } from "@neurosolutionsgroup/models";
import { useTranslation } from "react-i18next";
import ControlledDrawer from "../../ControlledDrawer/ControlledDrawer";
import DrawerMenuSection from "../../ControlledDrawer/DrawerMenuSection";

export interface DatePickerProps {
  date: Date | null;
  onChange: (date: Date) => void;
  language: Language;
  label?: string;
  minDate?: Date;
  maxDate?: Date;
  disableFuture?: boolean;
  fullWidth?: boolean;
  safeAreas?: SafeAreas;
}

const DatePicker = ({
  date,
  onChange,
  language,
  label,
  minDate,
  maxDate,
  disableFuture,
  fullWidth,
  safeAreas,
}: DatePickerProps): JSX.Element => {
  const { palette } = useTheme();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const formattedDate = useMemo((): string | undefined => {
    if (date) {
      return new Date(date).toLocaleDateString(language, {
        dateStyle: "long",
      });
    }
    return t("general.actions.select");
  }, [date, language]);

  const onDatePickerChange = (date: Date | null, close: boolean) => {
    if (date) {
      onChange(date);
    }

    if (close) {
      setOpen(false);
    }
  };

  const renderDay = (
    day: Date,
    _selectedDays: (Date | null)[],
    pickerDayProps: PickersDayProps<Date>
  ) => {
    return (
      <PickersDay
        onClick={
          pickerDayProps.selected
            ? () => onDatePickerChange(day, true)
            : undefined
        }
        {...pickerDayProps}
        sx={{
          [`&.${pickersDayClasses.selected}`]: {
            backgroundColor: palette.secondary.main,
          },
          [`&.${pickersDayClasses.selected}:hover`]: {
            backgroundColor: palette.secondary.light,
          },
        }}
      />
    );
  };

  return (
    <>
      <Button
        variant="text"
        color="secondary"
        onClick={() => setOpen(true)}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          minWidth: "150px",
        }}
        fullWidth={fullWidth}
      >
        {formattedDate} <CalendarMonthIcon sx={{ marginLeft: 1 }} />
      </Button>
      <ControlledDrawer
        anchor="bottom"
        open={open}
        onClose={() => setOpen(false)}
        safeAreas={safeAreas}
      >
        {label ? (
          <DrawerMenuSection variant="header">
            <Typography variant="h4">{label}</Typography>
          </DrawerMenuSection>
        ) : null}
        <Box p={1} display="flex" flexDirection="column" alignItems="center">
          <StaticDatePicker
            value={date}
            onChange={(value) => onDatePickerChange(value, false)}
            onAccept={(value) => onDatePickerChange(value, true)}
            renderInput={() => <>{/* unused in desktop mode */}</>}
            renderDay={renderDay}
            displayStaticWrapperAs="desktop"
            views={["year", "month", "day"]}
            disableFuture={disableFuture}
            minDate={minDate}
            maxDate={maxDate}
          />
        </Box>
      </ControlledDrawer>
    </>
  );
};

export default DatePicker;
