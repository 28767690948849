import { Button, ButtonProps, CircularProgress } from "@mui/material";
import Logos from "assets/logo";
import React from "react";
import { useTranslation } from "react-i18next";

interface AppleButtonProps extends ButtonProps {
  authVariant: "continue" | "signin";
  loading?: boolean;
}

const GoogleButton: React.FC<AppleButtonProps> = ({
  authVariant,
  loading,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Button
      {...props}
      color="secondary"
      variant="text"
      fullWidth
      sx={{
        img: {
          height: "1.5rem",
          width: "1.5rem",
          marginRight: "6px",
        },
      }}
    >
      {loading ? (
        <CircularProgress size="1.5rem" color="secondary" />
      ) : (
        <>
          <img src={Logos.GoogleSmall} alt="" />{" "}
          {t(`auth.google.${authVariant}`)}
        </>
      )}
    </Button>
  );
};

export default GoogleButton;
