import { CalendarToday as CalendarTodayIcon } from "@mui/icons-material";
import {
  Badge,
  badgeClasses,
  Box,
  Button,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import {
  PickersDay,
  PickersDayProps,
  StaticDatePicker,
} from "@mui/x-date-pickers";
import { Language } from "@neurosolutionsgroup/models";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ControlledDrawer from "../../ControlledDrawer/ControlledDrawer";
import DrawerMenuSection from "../../ControlledDrawer/DrawerMenuSection";
import { Tools } from "@neurosolutionsgroup/tools";
import { intervalToDuration, isBefore, isSameDay, Locale, sub } from "date-fns";

export interface WeekPickerProps {
  date: Date | null;
  onChange: (date: Date) => void;
  language: Language;
  locale: Locale;
  taggedDates?: Date[];
  label?: string;
  minDate?: Date;
  maxDate?: Date;
  disableFuture?: boolean;
  fullWidth?: boolean;
}

const WeekPicker = ({
  date,
  onChange,
  language,
  locale,
  taggedDates,
  label,
  minDate,
  maxDate,
  disableFuture,
  fullWidth,
}: WeekPickerProps): JSX.Element => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const formattedDate = useMemo((): string | undefined => {
    if (date) {
      return Tools.Time.Strings.localizedPeriodFromDates(
        sub(date, { days: 6 }),
        date,
        locale
      );
    }

    return t("general.actions.select");
  }, [date, language]);

  const onDatePickerChange = (date: Date | null, close: boolean) => {
    if (date) {
      onChange(date);
    }

    if (close) {
      setOpen(false);
    }
  };

  const dateIsTagged = (dateArray: Date[], d: Date): boolean => {
    return dateArray.some((td) => isSameDay(d, td));
  };

  // Custom render for calendar days in order to highlight previous days.
  const renderDay = (
    day: Date,
    selectedDays: (Date | null)[],
    pickerDayProps: PickersDayProps<Date>
  ) => {
    if (
      selectedDays.length === 0 ||
      !(day instanceof Date) ||
      !(selectedDays[0] instanceof Date)
    ) {
      return (
        <Badge
          variant="dot"
          color="secondary"
          sx={{ [`& .${badgeClasses.badge}`]: { top: "10px", right: "10px" } }}
          invisible={
            taggedDates
              ? !(
                  dateIsTagged(taggedDates, day) &&
                  !pickerDayProps.outsideCurrentMonth
                )
              : true
          }
          key={pickerDayProps.key}
        >
          <PickersDay {...pickerDayProps} />
        </Badge>
      );
    }

    const { selected, ...propsToPass } = pickerDayProps;

    const selectedDate = selectedDays[0];

    const beforeSelectedDate = isBefore(day, selectedDate) || selected;

    let style = undefined;

    if (beforeSelectedDate) {
      const diff = intervalToDuration({
        start: day,
        end: selectedDate,
      });

      const dayDiff = diff.days;

      const monthDiff = diff.months;

      const baseHighlightStyle: SxProps<Theme> = (theme) => ({
        "borderWidth": "1px",
        "borderColor": theme.palette.secondary.main,
        "borderStyle": "solid",
        "boxSizing": "border-box",
        "marginX": 0,
        "width": "40px",
        "&:hover": {
          backgroundColor: theme.palette.secondary.main,
          color: "#fff",
        },
      });

      const startStyle: SxProps<Theme> = (theme) => ({
        ...baseHighlightStyle(theme),
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderRight: "none",
      });

      const middleStyle: SxProps<Theme> = (theme) => ({
        ...baseHighlightStyle(theme),
        borderLeft: "none",
        borderRight: "none",
        borderRadius: 0,
      });

      const endStyle: SxProps<Theme> = (theme) => ({
        ...baseHighlightStyle(theme),
        borderLeft: "none",
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        backgroundColor: theme.palette.secondary.main,
        color: "#fff",
      });

      if (dayDiff !== undefined && monthDiff === 0) {
        if (dayDiff === 7 - 1) {
          style = startStyle;
        } else if (dayDiff < 7 - 1 && dayDiff > 0) {
          style = middleStyle;
        } else if (selected) {
          style = endStyle;
        }
      }
    }

    return (
      <Badge
        variant="dot"
        color="secondary"
        sx={{ [`& .${badgeClasses.badge}`]: { top: "10px", right: "10px" } }}
        invisible={
          taggedDates
            ? !(
                dateIsTagged(taggedDates, day) &&
                !propsToPass.outsideCurrentMonth
              )
            : true
        }
        key={propsToPass.key}
      >
        <PickersDay
          sx={style}
          {...propsToPass}
          onClick={
            pickerDayProps.selected
              ? () => onDatePickerChange(day, true)
              : undefined
          }
        />
      </Badge>
    );
  };

  return (
    <>
      <Button
        variant="text"
        color="secondary"
        onClick={() => setOpen(true)}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          minWidth: "150px",
        }}
        fullWidth={fullWidth}
      >
        {formattedDate} <CalendarTodayIcon sx={{ marginLeft: 1 }} />
      </Button>
      <ControlledDrawer
        anchor="bottom"
        open={open}
        onClose={() => setOpen(false)}
      >
        {label ? (
          <DrawerMenuSection variant="header">
            <Typography variant="h4">{label}</Typography>
          </DrawerMenuSection>
        ) : null}
        <Box p={1} display="flex" flexDirection="column" alignItems="center">
          <StaticDatePicker
            value={date}
            onChange={(value) => onDatePickerChange(value, false)}
            onAccept={(value) => onDatePickerChange(value, true)}
            renderInput={() => <>{/* unused in desktop mode */}</>}
            renderDay={renderDay}
            displayStaticWrapperAs="desktop"
            views={["year", "month", "day"]}
            disableFuture={disableFuture}
            minDate={minDate}
            maxDate={maxDate}
          />
        </Box>
      </ControlledDrawer>
    </>
  );
};

export default WeekPicker;
