import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { GenericPageProps } from "../../genericPageProps";
import {
  ChildIconsDefintion,
  ChildSecurityOption,
  Gender,
} from "@neurosolutionsgroup/models";
import AddChildForm, {
  AddChildGenericInfoProps,
} from "./AddChildForm/AddChildForm";
import { Dispatch, SetStateAction, useMemo } from "react";
import { BackButton, FullscreenPage } from "@neurosolutionsgroup/components";
import { sub } from "date-fns";

export interface ChildCreationFormState {
  name: string;
  gender: Gender | null | undefined;
  icon: number | null;
  birthDateMS: number | null;
  childSecurity: ChildSecurityOption | null;
}

export interface ChildCreationPageProps extends GenericPageProps {
  childIcons: ChildIconsDefintion;
  formState: ChildCreationFormState;
  setFormState: Dispatch<SetStateAction<ChildCreationFormState>>;
  infoCoachImage: string;
  onComplete: (formState: ChildCreationFormState) => void;
  placeholderName?: string;
  onBack?: VoidFunction;
}

const ChildCreationPage = ({
  childIcons,
  formState,
  setFormState,
  infoCoachImage,
  onComplete,
  placeholderName,
  onBack,
  safeAreas,
  sx,
}: ChildCreationPageProps): JSX.Element => {
  const { t } = useTranslation();

  const showDobWarning = useMemo(() => {
    if (formState.birthDateMS === null) {
      return false;
    } else {
      const sixYearsBirthDate = sub(new Date(), { years: 6 }).getTime();

      const thirteenYearsBirthDate = sub(sixYearsBirthDate, {
        years: 7,
      }).getTime();

      if (
        formState.birthDateMS > sixYearsBirthDate ||
        formState.birthDateMS < thirteenYearsBirthDate
      ) {
        return true;
      } else {
        return false;
      }
    }
  }, [formState]);

  const genericInfoProps: AddChildGenericInfoProps = {
    name: formState.name,
    setName: (name) => setFormState((current) => ({ ...current, name })),
    icon: formState.icon,
    setIcon: (icon) => setFormState((current) => ({ ...current, icon })),
    gender: formState.gender,
    setGender: (gender) => setFormState((current) => ({ ...current, gender })),
    date: formState.birthDateMS,
    setDate: (birthDateMS) =>
      setFormState((current) => ({ ...current, birthDateMS })),
    showDobWarning,
    childSecurity: formState.childSecurity,
    setChildSecurity: (childSecurity) =>
      setFormState((current) => ({ ...current, childSecurity })),
  };

  const isChildValid = useMemo((): boolean => {
    return (
      formState.icon !== null &&
      formState.name !== "" &&
      formState.gender !== undefined &&
      formState.birthDateMS !== null &&
      formState.childSecurity !== null
    );
  }, [formState]);

  const onConfirm = () => {
    onComplete(formState);
  };

  return (
    <FullscreenPage
      sx={[
        {
          paddingTop: safeAreas.top,
          paddingBottom: safeAreas.bottom,
          paddingX: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Box maxWidth="100%" pt={2}>
        {onBack ? <BackButton onClick={onBack} /> : null}

        <Typography mt={1} mb={2} variant="h2">
          {t("settings.child.profile")}
        </Typography>

        <AddChildForm
          genericInfoProps={genericInfoProps}
          onConfirm={() => {}}
          confirmText={t("navigation.firstChildCreation.confirmText")}
          isValid={!isChildValid}
          childIcons={childIcons}
          coachImage={infoCoachImage}
          placeholderName={placeholderName}
        />
      </Box>

      <Box mt={1} mb={3}>
        <Button
          onClick={onConfirm}
          color="secondary"
          variant="contained"
          disabled={!isChildValid}
          data-cy="submit-child"
        >
          {t("general.actions.confirm")}
        </Button>
      </Box>
    </FullscreenPage>
  );
};

export default ChildCreationPage;
