import { Box, Button, Typography, drawerClasses } from "@mui/material";
import { InfoOutlined as InfoOutlinedIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import ControlledDrawer, {
  ControlledDrawerProps,
} from "../ControlledDrawer/ControlledDrawer";

export interface InfoDrawerProps extends ControlledDrawerProps {
  text: string | string[] | JSX.Element;
  coachImage: string | null;
  title?: string;
  showInfoIcon?: boolean;
  infoIconImage?: string;
}

export const InfoDrawer = ({
  className,
  text,
  title,
  showInfoIcon = true,
  coachImage,
  infoIconImage,
  ...props
}: InfoDrawerProps): JSX.Element => {
  const ROOT_CLASS = "info-drawer";

  const { t } = useTranslation();

  return (
    <ControlledDrawer
      {...props}
      classes={{ paper: className }}
      sx={{
        [`.${drawerClasses.paper}`]: {
          padding: 1,
        },
        [`& .${ROOT_CLASS}__coach`]: {
          height: "15vh",
          objectFit: "contain",
        },
      }}
    >
      <Box display="flex" alignItems={"center"} mb={1}>
        {infoIconImage ? (
          <Box
            sx={{
              marginRight: "auto",
              paddingRight: 0,
              img: { width: "75px", height: "75px", objectFit: "contain" },
            }}
          >
            <img src={infoIconImage} alt="" />
          </Box>
        ) : showInfoIcon ? (
          <InfoOutlinedIcon
            color="secondary"
            sx={{ marginRight: "auto", paddingRight: 0 }}
          />
        ) : undefined}
        {title && (
          <Box mx={1}>
            <Typography variant="h3" textAlign="center">
              {title}
            </Typography>
          </Box>
        )}
        {infoIconImage ? (
          <Box
            sx={{
              marginLeft: "auto",
              paddingLeft: 0,
              visibility: "hidden",
              img: { width: "75px", height: "75px" },
            }}
          >
            <img src={infoIconImage} alt="" />
          </Box>
        ) : showInfoIcon ? (
          <InfoOutlinedIcon
            color="secondary"
            sx={{ marginLeft: "auto", paddingLeft: 0, visibility: "hidden" }}
          />
        ) : undefined}
      </Box>
      <Box mx={1}>
        {Array.isArray(text) ? (
          text.map((t, i) => (
            <Typography key={i} textAlign="center" fontSize="0.8rem" mb={1}>
              {t}
            </Typography>
          ))
        ) : typeof text === "string" ? (
          <Typography textAlign="center" fontSize="0.8rem">
            {text}
          </Typography>
        ) : (
          text
        )}
      </Box>
      {coachImage ? (
        <img src={coachImage} className={ROOT_CLASS + "__coach"} alt="" />
      ) : null}
      <Box display="flex" justifyContent="center" mt={1}>
        <Button variant="contained" color="secondary" onClick={props.onClose}>
          {t("general.understood")}
        </Button>
      </Box>
    </ControlledDrawer>
  );
};

export default InfoDrawer;
