import { ButtonBase, Typography, useTheme } from "@mui/material";
import { SxProps, Theme } from "@mui/system";
import { Box } from "@mui/system";
import { SharedStyles } from "@neurosolutionsgroup/themes";
import Icons from "../Icons";

export interface AddFirstItemButtonProps {
  onClick: () => void;
  label?: string;
  sx?: SxProps<Theme>;
  sxWrapper?: SxProps<Theme>;
  sticky?: boolean;
}

const AddFirstItemButton: React.FC<AddFirstItemButtonProps> = ({
  label,
  onClick,
  sx,
  sxWrapper,
  sticky = false,
}) => {
  const { palette } = useTheme();

  return (
    <Box
      sx={[
        {
          ...SharedStyles.Layout.flexCenter,
          flexDirection: "column",
          position: sticky ? "fixed" : "relative",
          zIndex: 1,
        },
        ...(sticky
          ? [
              {
                bottom: 0,
                right: 0,
              },
            ]
          : []),
        ...(Array.isArray(sxWrapper) ? sxWrapper : [sxWrapper]),
      ]}
    >
      {label ? (
        <Typography fontWeight="bold" color="#000" mb={1}>
          {label}
        </Typography>
      ) : null}
      <ButtonBase
        data-cy="create-first-routine"
        onClick={onClick}
        sx={[
          {
            position: "relative",
            backgroundColor: palette.primary.main,
            width: "3rem",
            height: "3rem",
            borderRadius: "1.5rem",
            padding: "0.5rem",
            svg: {
              width: "2rem",
              height: "2rem",
            },
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        <Icons.AddIcon color="#fff" />
      </ButtonBase>
    </Box>
  );
};

export default AddFirstItemButton;
