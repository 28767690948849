import { CalendarMonthOutlined as CalendarMonthIcon } from "@mui/icons-material";
import {
  Badge,
  badgeClasses,
  Box,
  Button,
  Typography,
  useTheme,
} from "@mui/material";
import { CypressProps, Language, SafeAreas } from "@neurosolutionsgroup/models";
import { useEffect, useMemo, useState } from "react";
import ControlledDrawer from "../../ControlledDrawer/ControlledDrawer";
import DrawerMenuSection from "../../ControlledDrawer/DrawerMenuSection";
import {
  PickersDay,
  PickersDayProps,
  StaticDatePicker,
} from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";
import { isSameDay } from "date-fns";

export interface MultiDatePickerProps extends CypressProps {
  dates: Date[];
  onChange: (dates: Date[]) => void;
  language: Language;
  taggedDates?: Date[];
  label?: string;
  minDate?: Date;
  maxDate?: Date;
  disableFuture?: boolean;
  maxDates?: number;
  safeAreas?: SafeAreas;
}

const MultiDatePicker = ({
  dates,
  onChange,
  language,
  taggedDates,
  label,
  minDate,
  maxDate,
  disableFuture,
  maxDates = 7,
  safeAreas,
  ...props
}: MultiDatePickerProps): JSX.Element => {
  const { palette } = useTheme();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);
  const [showMaxDatesWarning, setShowMaxDatesWarning] = useState(false);

  useEffect(() => {
    setSelectedDates(dates);
  }, [dates]);

  const displayValue = useMemo((): string => {
    if (dates.length === 0) {
      return t("general.actions.select");
    }

    if (dates.length < 3) {
      return dates
        .sort((a, b) => a.getTime() - b.getTime())
        .map((d) =>
          new Date(d).toLocaleDateString(language, {
            dateStyle: "long",
          })
        )
        .join(" & ");
    }

    return `${dates.length.toFixed(0)} dates selected`;
  }, [dates, language]);

  const onDatePickerChange = (date: Date | null) => {
    if (date) {
      if (
        selectedDates.length >= maxDates &&
        !selectedDates.map((d) => d.getTime()).includes(date.getTime())
      ) {
        setShowMaxDatesWarning(true);
        return;
      }

      setShowMaxDatesWarning(false);

      setSelectedDates((current) => {
        if (current.map((d) => d.getTime()).includes(date.getTime())) {
          return [...current.filter((d) => d.getTime() !== date.getTime())];
        } else {
          return [...current, date];
        }
      });
    }
  };

  const onClose = () => {
    setOpen(false);
    setShowMaxDatesWarning(false);
    setSelectedDates(dates);
  };

  const onConfirm = () => {
    onChange(selectedDates);
    setOpen(false);
    setShowMaxDatesWarning(false);
  };

  const dateIsTagged = (dateArray: Date[], d: Date): boolean => {
    return dateArray.some((td) => isSameDay(d, td));
  };

  const renderDay = (
    day: Date,
    _selectedDays: (Date | null)[],
    pickerDayProps: PickersDayProps<Date>
  ) => {
    return (
      <Badge
        variant="dot"
        color="secondary"
        sx={{
          [`& .${badgeClasses.badge}`]: {
            top: "10px",
            right: "10px",
            border: "1px solid #fff",
          },
        }}
        invisible={
          taggedDates
            ? !(
                dateIsTagged(taggedDates, day) &&
                !pickerDayProps.outsideCurrentMonth
              )
            : true
        }
        key={pickerDayProps.key}
      >
        <PickersDay
          {...pickerDayProps}
          sx={
            selectedDates.map((date) => date.getTime()).includes(day.getTime())
              ? {
                  backgroundColor: palette.secondary.main,
                  color: palette.secondary.contrastText,
                  [`&:hover`]: {
                    backgroundColor: palette.secondary.light,
                  },
                  [`&:focus`]: {
                    backgroundColor: palette.secondary.light,
                  },
                }
              : undefined
          }
          data-cy={"date-picker-day"}
        />
      </Badge>
    );
  };

  return (
    <>
      <Button
        variant="text"
        color="secondary"
        onClick={() => setOpen(true)}
        data-cy={props["data-cy"] ?? "multi-date-picker"}
      >
        {displayValue} <CalendarMonthIcon sx={{ marginLeft: 1 }} />
      </Button>
      <ControlledDrawer
        anchor="bottom"
        open={open}
        onClose={onClose}
        safeAreas={safeAreas}
      >
        {label ? (
          <DrawerMenuSection variant="header">
            <Typography variant="h4">{label}</Typography>
          </DrawerMenuSection>
        ) : null}
        <Box p={1} display="flex" flexDirection="column" alignItems="center">
          <StaticDatePicker
            value={[]}
            onChange={(value) => onDatePickerChange(value)}
            renderDay={renderDay}
            renderInput={() => <>{/* unused in desktop mode */}</>}
            displayStaticWrapperAs="desktop"
            views={["day"]}
            disableFuture={disableFuture}
            minDate={minDate}
            maxDate={maxDate}
          />
        </Box>
        {showMaxDatesWarning ? (
          <Box px={2} pb={2}>
            <Typography color="error" textAlign="center">
              {t("general.dateMax", { count: maxDates })}
            </Typography>
          </Box>
        ) : null}
        <Box mt="auto" px={2} display="flex" justifyContent="center">
          <Button
            variant="text"
            onClick={onClose}
            sx={{
              marginRight: 2,
            }}
          >
            {t("general.actions.cancel")}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={onConfirm}
            data-cy="multi-date-picker-confirm"
          >
            {t("general.actions.confirm")}
          </Button>
        </Box>
      </ControlledDrawer>
    </>
  );
};

export default MultiDatePicker;
