import React, { useState } from "react";
import { GenericPageProps } from "../genericPageProps";
import {
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  FormControlLabelProps,
  useTheme,
  Link,
} from "@mui/material";
import AuthpageHeader from "./components/AuthPageHeader";
import { Trans, useTranslation } from "react-i18next";
import { Language } from "@neurosolutionsgroup/models";
import AuthPageContainer from "./components/AuthPageContainer";
import AuthContinueButton from "./components/AuthNextButton";

export interface PoliciesPageProps extends GenericPageProps {
  onContinue: VoidFunction;
  onBack: VoidFunction;
  openTerms: (language: Language) => void;
  openPrivacyPolicy: (language: Language) => void;
  open: boolean;
  language: Language;
  loading: boolean;
  logoImageString: string;
  privacyEmail: string;
}

const PoliciesPage: React.FC<PoliciesPageProps> = ({
  onContinue,
  onBack,
  openTerms,
  openPrivacyPolicy,
  open,
  language,
  loading,
  logoImageString,
  privacyEmail,
  productName,
  safeAreas,
}) => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  const [termsAccepted, setTermsAccepted] = useState(false);
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);

  const handleExternalLinkClicked = (link: "terms" | "privacy") => {
    if (link === "terms") {
      openTerms(language);
    } else {
      openPrivacyPolicy(language);
    }
  };

  const privacyPolicyComponents = {
    control: (
      <Checkbox
        checked={privacyPolicyAccepted}
        onChange={(e) => {
          setPrivacyPolicyAccepted(e.target.checked);
        }}
        data-cy={"checkbox-privacy"}
      />
    ),
    label: (
      <Typography fontSize="0.75rem">
        <Trans i18nKey={"auth.signup.privacyCheckbox"}>
          I accept the "product name"{" "}
          <Link
            color={palette.primary.dark}
            onClick={(e) => {
              e.preventDefault();
              handleExternalLinkClicked("privacy");
            }}
            href="privacy"
          >
            Privacy Policy
          </Link>
        </Trans>
      </Typography>
    ),
  };

  const termsComponents = {
    control: (
      <Checkbox
        checked={termsAccepted}
        onChange={(e) => {
          setTermsAccepted(e.target.checked);
        }}
        data-cy={"checkbox-terms"}
      />
    ),
    label: (
      <Typography fontSize="0.75rem">
        <Trans i18nKey={"auth.signup.termsCheckbox"}>
          I accept the "product name"{" "}
          <Link
            color={palette.primary.dark}
            onClick={(e) => {
              e.preventDefault();
              handleExternalLinkClicked("terms");
            }}
            href="terms"
          >
            Terms and Conditions
          </Link>
        </Trans>
      </Typography>
    ),
  };

  return (
    <AuthPageContainer open={open} safeAreas={safeAreas}>
      <Grid item container spacing={4}>
        <AuthpageHeader
          logoImageString={logoImageString}
          onBack={onBack}
          imageAlt={productName ?? ""}
        />
        <Grid item container direction="column" spacing={1} mx={2}>
          <Grid item container>
            <Typography textAlign={"justify"}>
              {t("auth.signup.policyExplanation.first")}
            </Typography>
          </Grid>
          <Grid item container textAlign={"justify"}>
            <Typography textAlign={"justify"}>
              <b>{t("auth.signup.policyExplanation.second")}</b>
            </Typography>
          </Grid>
        </Grid>

        <Grid item container direction="column" mx={2}>
          <PoliciesCheckbox
            control={privacyPolicyComponents.control}
            label={privacyPolicyComponents.label}
          />
          <PoliciesCheckbox
            control={termsComponents.control}
            label={termsComponents.label}
          />
        </Grid>

        <AuthContinueButton
          text={t("general.actions.continuer")}
          disabled={!(termsAccepted && privacyPolicyAccepted)}
          loading={loading}
          onContinue={onContinue}
          dataCy="policies-button"
        />
      </Grid>

      <Grid item px={2}>
        <Typography textAlign="center" fontSize="0.8rem">
          {t("auth.signup.policyHelp")}
          {privacyEmail}
        </Typography>
      </Grid>
    </AuthPageContainer>
  );
};

export default PoliciesPage;

const PoliciesCheckbox: React.FC<FormControlLabelProps> = ({
  control,
  label,
}) => {
  return (
    <Grid item>
      <FormControlLabel control={control} label={label}></FormControlLabel>
    </Grid>
  );
};
