import { Slider } from "@mui/material";
import { Box } from "@mui/system";
import {
  Dose,
  DoseUnits,
  MedicationCategory,
} from "@neurosolutionsgroup/models";
import { TimePicker } from "common/Components/TimePicker";
import useMedication from "common/hooks/medications/useMedication";
import useLanguage from "common/hooks/Parameters/useLanguage";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomDosagePicker from "./CustomDosagePicker";
import UnitPicker from "./UnitPicker";
import { Icons } from "@neurosolutionsgroup/components";

export interface DosagePickerProps {
  dose: Dose;
  updateTime: (time: number) => void;
  updateDoseValue: (dose: number) => void;
  updateDoseUnits: (units: DoseUnits) => void;
  removeDose: () => void;
  medication: string;
  label?: string;
}

const DosagePicker: React.FC<DosagePickerProps> = ({
  dose,
  updateTime,
  updateDoseValue,
  updateDoseUnits,
  removeDose,
  medication,
  label,
}) => {
  const { t } = useTranslation();
  const { language } = useLanguage();

  const {
    selectors: { medicationById },
  } = useMedication();

  const [unitDrawerOpen, setUnitDrawerOpen] = useState<boolean>(false);

  const marks = useMemo(() => {
    const marks = [{ value: 0 }];

    if (medicationById && medicationById[medication]) {
      const medicationDef = medicationById[medication];

      marks.push(
        ...medicationDef.dosage.map((d) => {
          return {
            value: d,
          };
        })
      );
    }

    return marks;
  }, [medication]);

  const isMedicationPrivate = (): boolean => {
    if (
      medicationById &&
      medicationById[medication] &&
      medicationById[medication].category === MedicationCategory.MyMedication
    ) {
      return true;
    }
    return false;
  };

  return (
    <Box
      className="dosage-input"
      p={2}
      display="flex"
      flexDirection="column"
      borderRadius={10}
    >
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        {label && <h4>{label}</h4>}
        <Icons.CloseIcon onClick={removeDose} color="#903630" />
      </Box>
      <p>{t("medication.followUp.edit.selectDosage")}</p>
      {}
      {isMedicationPrivate() ? (
        <CustomDosagePicker
          unit={dose.units ?? "mg"}
          doseValue={dose.quantity}
          updateDoseValue={updateDoseValue}
          setOpen={setUnitDrawerOpen}
        />
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
        >
          <Slider
            marks={marks}
            step={null}
            min={marks[0].value}
            max={marks[marks.length - 1].value}
            value={dose.quantity}
            onChange={(_, v) => updateDoseValue(v as number)}
            className="dose-slider"
            color="secondary"
          />
          <Box>
            {dose.quantity} {dose.units}
          </Box>
        </Box>
      )}
      <Box pt={1} display="flex" flexDirection="column" alignContent="center">
        <Box textAlign="center" p={1}>
          {t("medication.followUp.edit.selectTime")}
        </Box>
        <TimePicker
          time={dose.time}
          setTime={updateTime}
          language={language}
          showLabel={false}
        />
      </Box>
      <UnitPicker
        open={unitDrawerOpen}
        setOpen={setUnitDrawerOpen}
        setValue={updateDoseUnits}
      />
    </Box>
  );
};
export default DosagePicker;
