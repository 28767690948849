import {
  Box,
  Button,
  ButtonBase,
  Grid,
  Input,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import useUserProfile from "common/hooks/account/useUserProfile";
import React, { useEffect, useMemo, useState, useRef } from "react";
import { Trans, useTranslation } from "react-i18next";
import { SAFE_AREA_BOTTOM } from "stylesheets";
import SHA256 from "crypto-js/sha256";
import { WebviewLink } from "@neurosolutionsgroup/components";
import { Icons } from "@neurosolutionsgroup/components";
import PinAssets from "assets/pin-icons";

export interface ParentalCodeGateProps {
  show: boolean;
  onComplete: VoidFunction;
  onClose: VoidFunction;
  onForgotCode: VoidFunction;
}

const ParentalCodeGate: React.FC<ParentalCodeGateProps> = ({
  show,
  onComplete,
  onClose,
  onForgotCode,
}) => {
  const { t } = useTranslation();
  const {
    selectors: { userProfile },
    actions: { updateMasterSettingsKey },
  } = useUserProfile();

  const [code, setCode] = useState("");
  const [error, setError] = useState<
    "code" | "complete" | "length" | undefined
  >();

  useEffect(() => {
    if (show) {
      ref.current?.focus();
    }
    if (process.env.REACT_APP_SKIP_PARENT_CODE === "true" && show) {
      onComplete();
    }
  }, [show]);

  const userHasRegisteredPin = useMemo((): boolean => {
    if (userProfile?.masterSettings?.settings["nip"]) {
      return true;
    }
    return false;
  }, [userProfile]);

  const codeMatchRegisteredPin = (): boolean => {
    if (userHasRegisteredPin) {
      if (code === userProfile?.masterSettings?.settings["nip"]) {
        return true;
      }

      const codeSHA256 = SHA256(code).toString();

      if (codeSHA256 === userProfile?.masterSettings?.settings["nip"]) {
        return true;
      }
    }
    return false;
  };

  const codeIsShorter = useMemo((): boolean => {
    return code.length < 4;
  }, [code]);

  const codeIsLonger = useMemo((): boolean => {
    return code.length > 6;
  }, [code]);

  const codeIsValid = useMemo((): boolean => {
    return !codeIsShorter && !codeIsLonger;
  }, [code]);

  const onDrawerClose = () => {
    setCode("");
    setError(undefined);
    onClose();
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement> | undefined) => {
    //prevent page from refreshing is enter is press
    if (event) {
      event.preventDefault();
    }

    if (userHasRegisteredPin) {
      if (codeMatchRegisteredPin()) {
        onComplete();
      } else {
        setCode("");
        setError("code");
      }
    } else {
      if (codeIsValid) {
        setError(undefined);

        const codeSHA256 = SHA256(code).toString();

        updateMasterSettingsKey("nip", codeSHA256);
        onComplete();
      } else {
        if (codeIsShorter || codeIsLonger) {
          setError("length");
        }
      }
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const patern = new RegExp("^[0-9]*$");
    if (patern.test(newValue)) {
      setCode(newValue);
    }
  };

  const onClear = () => {
    setError(undefined);
    setCode("");
  };

  const ref = useRef<HTMLInputElement>(null);

  return (
    <SwipeableDrawer
      open={show}
      anchor="bottom"
      onClose={onDrawerClose}
      disableSwipeToOpen={true}
      onOpen={() => undefined}
    >
      <Box mt={4} mb={SAFE_AREA_BOTTOM} mx={2} pb={2}>
        <Grid container direction="column" alignItems="center" spacing={1}>
          <Grid
            item
            sx={{
              img: {
                width: "50px",
                height: "50px",
              },
            }}
          >
            <img src={PinAssets.Padlock} alt="" />
          </Grid>
          <Grid item mb={2}>
            <Typography variant="h4">
              {t(
                `navigation.parentalGate.${
                  userHasRegisteredPin ? "entry" : "creation"
                }.title`
              )}
            </Typography>
          </Grid>
          <Grid item textAlign="center">
            <Typography fontSize="0.8rem">
              <Trans
                i18nKey={`navigation.parentalGate.${
                  userHasRegisteredPin ? "entry" : "creation"
                }.text`}
              />
            </Typography>
          </Grid>
          <Grid item sx={{ width: "100%" }}>
            <form onSubmit={(e) => onSubmit(e)}>
              <Grid
                container
                direction="column"
                alignItems="center"
                spacing={1}
              >
                <Grid item alignSelf="stretch" mt={2}>
                  <Box display={"flex"}>
                    <Input
                      data-cy="parent-nip-code"
                      inputRef={ref}
                      sx={{
                        border: "1px solid",
                        borderColor: error ? "#ef4145" : "#31737c",
                        borderRadius: "10px",
                        paddingX: "1em",
                        width: "100%",
                        height: "2.5em",
                        borderWidth: "1px",
                        svg: {
                          height: "2.5em",
                          width: "2.5em",
                          path: {
                            opacity: "0.5",
                            fill: "#31737c",
                          },
                        },
                      }}
                      inputProps={{ pattern: "\\d*", inputMode: "numeric" }}
                      autoFocus={true}
                      disableUnderline={true}
                      value={code}
                      onChange={onChange}
                      endAdornment={
                        code.length > 0 ? (
                          <ButtonBase onClick={onClear}>
                            <Icons.CloseIcon withCircle color="#903630" />
                          </ButtonBase>
                        ) : null
                      }
                      placeholder={t("navigation.parentalGate.placeholder")}
                    />
                  </Box>
                </Grid>
                {error ? (
                  <Grid item mb={1}>
                    <Typography
                      color="error"
                      textAlign="center"
                      fontSize="0.8rem"
                    >
                      {t(`navigation.parentalGate.error.${error}`)}
                    </Typography>
                  </Grid>
                ) : null}
                <Grid item>
                  <Button
                    data-cy="submit-parent-nip"
                    onClick={() => onSubmit(undefined)}
                    type="submit"
                    variant="contained"
                    color="secondary"
                  >
                    {t("general.actions.confirm")}
                  </Button>
                </Grid>
                {userHasRegisteredPin ? (
                  <Grid textAlign="center" mt={1}>
                    <Typography fontSize="0.8rem" color="secondary">
                      <WebviewLink
                        onClick={() => {
                          onForgotCode();
                        }}
                      >
                        <Trans i18nKey="navigation.parentalGate.entry.forgottenCode" />
                      </WebviewLink>
                    </Typography>
                  </Grid>
                ) : null}
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Box>
    </SwipeableDrawer>
  );
};
export default ParentalCodeGate;
