import { Grid, Link, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import AppleButton from "./components/AppleButton";
import AuthTextField from "./components/AuthTextField";
import GoogleButton from "./components/GoogleButton";
import { GenericPageProps } from "../genericPageProps";
import AuthpageHeader from "./components/AuthPageHeader";
import AuthPageContainer from "./components/AuthPageContainer";
import AuthContinueButton from "./components/AuthNextButton";

interface SignUpPageProps extends GenericPageProps {
  onContinueEmail: (email: string) => void;
  onContinueGoogle?: VoidFunction;
  onContinueApple?: VoidFunction;
  onBack: VoidFunction;
  open: boolean;
  loading: boolean;
  logoImageString: string;
  productName: string;
}

const SignUpPage: React.FC<SignUpPageProps> = ({
  onContinueEmail,
  onContinueGoogle,
  onContinueApple,
  onBack,
  open,
  loading,
  logoImageString,
  productName,
  os,
  safeAreas,
}) => {
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);

  const validate = () => {
    return new RegExp(
      /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ).test(email);
  };

  const onEmailBlur = () => {
    setError(
      // Email RexExp taken from standard RFC 5322 and adapted for JavaScript from emailregex.com.
      !validate()
    );
  };

  useEffect(() => {
    // Reset error if email changed.
    setError(false);
  }, [email]);

  return (
    <AuthPageContainer open={open} safeAreas={safeAreas}>
      <Grid item container spacing={4} justifyContent={"center"}>
        <AuthpageHeader
          logoImageString={logoImageString}
          onBack={onBack}
          imageAlt={productName}
        />
        <Grid item xs={12}>
          <Grid container direction="column" spacing={2} px={2}>
            <Grid item textAlign={"center"}>
              <Typography variant="h4">{t("auth.signup.title")}</Typography>
            </Grid>

            <Grid item>
              <AuthTextField
                data-cy="email-input"
                id="email-input"
                label={t("auth.inputs.email.label")}
                value={email}
                onChange={(e) => setEmail(e.currentTarget.value)}
                type="email"
                autoComplete="username"
                onBlur={onEmailBlur}
                error={error}
                errorText={t("auth.inputs.email.error.format")}
                placeholder={t("auth.inputs.email.placeholder")}
              />
            </Grid>

            <AuthContinueButton
              text={t("general.actions.continuer")}
              disabled={email.length === 0 || error || loading}
              loading={loading}
              onContinue={() => {
                if (validate()) {
                  onContinueEmail(email);
                } else {
                  setError(true);
                }
              }}
            />

            {onContinueApple || onContinueGoogle ? (
              <Grid item display="flex" justifyContent="center">
                <div className="signup__divider">
                  <Typography
                    className="signup__divider--text"
                    fontWeight="bold"
                    fontSize="0.8rem"
                  >
                    {t("general.or")}
                  </Typography>
                </div>
              </Grid>
            ) : null}
            {os === "android" ? (
              <>
                {onContinueGoogle ? (
                  <Grid item>
                    <GoogleButton
                      onClick={onContinueGoogle}
                      type="button"
                      authVariant="continue"
                      disabled={loading}
                      data-cy="signup-google-button"
                    />
                  </Grid>
                ) : null}
                {onContinueApple ? (
                  <Grid item>
                    <AppleButton
                      onClick={onContinueApple}
                      type="button"
                      authVariant="continue"
                      disabled={loading}
                      data-cy="signup-apple-button"
                    />
                  </Grid>
                ) : null}
              </>
            ) : (
              <>
                {onContinueGoogle ? (
                  <Grid item>
                    <GoogleButton
                      onClick={onContinueGoogle}
                      type="button"
                      authVariant="continue"
                      disabled={loading}
                      data-cy="signup-google-button"
                    />
                  </Grid>
                ) : null}
                {onContinueApple ? (
                  <Grid item>
                    <AppleButton
                      onClick={onContinueApple}
                      type="button"
                      authVariant="continue"
                      disabled={loading}
                      data-cy="signup-apple-button"
                    />
                  </Grid>
                ) : null}
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Typography textAlign="center" fontSize="0.8rem">
          <Link component={RouterLink} to="/login" color="secondary">
            {t("auth.signup.login")}
          </Link>
        </Typography>
      </Grid>
    </AuthPageContainer>
  );
};

export default SignUpPage;
