import { Box, useTheme } from "@mui/material";
import BackButton from "../BackButton/BackButton";
import { SafeAreas } from "@neurosolutionsgroup/models";

interface ChatHeaderProps {
  onBack: VoidFunction;
  safeAreas: SafeAreas;
}

const ChatHeader = ({ onBack, safeAreas }: ChatHeaderProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: "fixed",
        left: 0,
        top: 0,
        right: 0,
        height: `calc(${safeAreas.top} + 75px)`,
        backgroundImage:
          "linear-gradient(to bottom, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%)",
        padding: 1,
        paddingTop: `calc(6px + ${safeAreas.top})`,
      }}
    >
      <BackButton
        onClick={onBack}
        color={theme.palette.secondary.main}
        size="1.5rem"
      />
    </Box>
  );
};

export default ChatHeader;
