import {
  FirestoreCollection,
  FirestoreNoteDocument,
  FirestoreNotesSummaryDocument,
  Note,
} from "@neurosolutionsgroup/models";
import React, {
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import useChildren from "../children/useChildren";
import { sub } from "date-fns";
import {
  doc,
  getDoc,
  getFirestore,
  onSnapshot,
  Unsubscribe,
} from "firebase/firestore";
import { Tools } from "@neurosolutionsgroup/tools";

export interface NotesByDate {
  [key: string]: string[];
}

export interface NoteDateCount {
  date: Date;
  count: number;
}

export interface NoteDocsByDate {
  [dateKey: string]: Note[];
}

interface NotesData {
  noteDates: Date[];
  noteDateCounts: NoteDateCount[];
  notesByDate: NotesByDate;
  notesForWeek: NoteDocsByDate;
  currentDate: Date;
  setCurrentDate: React.Dispatch<SetStateAction<Date>>;
  setNoteDates: React.Dispatch<SetStateAction<Date[]>>;
  setNotesByDate: React.Dispatch<SetStateAction<NotesByDate>>;
  setDeletedNote: (noteId: string) => void;
  loading: boolean;
}

const [useNotesContext, NotesContextProvider] =
  Tools.Context.createGenericContext<NotesData>(__filename);

const NotesProvider: React.FC<PropsWithChildren> = (props) => {
  const { selectedChild } = useChildren().selectors;

  const [noteDates, setNoteDates] = useState<Date[]>([]);
  const [noteDateCounts, setNoteDateCounts] = useState<NoteDateCount[]>([]);
  const [notesByDate, setNotesByDate] = useState<NotesByDate>({});
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [notesForWeek, setNotesForWeek] = useState<{
    [dateKey: string]: Note[];
  }>({});
  const [loading, setLoading] = useState(false);

  const [deletedNotes, setDeletedNotes] = useState<string[]>([]);

  const setDeletedNote = (noteId: string) => {
    setDeletedNotes((current) => [...current, noteId]);
  };

  useEffect(() => {
    let unsub: Unsubscribe | null = null;

    const loadDates = async () => {
      if (selectedChild) {
        setLoading(true);

        const db = getFirestore();

        unsub = onSnapshot(
          doc(db, FirestoreCollection.NotesSummary, selectedChild),
          (docSnap) => {
            try {
              if (!docSnap.exists()) {
                console.warn(
                  `No note summary doc found for child: ${selectedChild}.`
                );
                setNoteDates([]);
                setNotesByDate({});
                return;
              }

              const summaryDoc =
                docSnap.data() as FirestoreNotesSummaryDocument;

              const dates: Date[] = [];
              const noteDates: NoteDateCount[] = [];
              const newNotesByDate: NotesByDate = {};

              summaryDoc.dates.forEach((d) => {
                if (d.notes.every((note) => deletedNotes.includes(note))) {
                  return;
                } else {
                  dates.push(Tools.Time.Dates.parseDateStringToJsDate(d.date));
                  noteDates.push({
                    date: Tools.Time.Dates.parseDateStringToJsDate(d.date),
                    count: d.notes.length,
                  });
                  newNotesByDate[d.date] = d.notes;
                }
              });

              setNoteDates(dates);
              setNoteDateCounts(noteDates);
              setNotesByDate(newNotesByDate);
            } finally {
              setLoading(false);
            }
          }
        );
      }

      setNoteDates([]);
    };

    loadDates();

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [selectedChild, deletedNotes]);

  /**
   * Load notes for a week when switching date.
   */
  useEffect(() => {
    const getNotesForWeek = async () => {
      if (!selectedChild || !currentDate) {
        return [];
      }

      setLoading(true);

      const dates: string[] = [];
      const weekNoteIdsByDate: { [dateKey: string]: string[] } = {};

      for (let i = 0; i < 7; i++) {
        const dateString = Tools.Time.Strings.getDateStringFromDate(
          sub(currentDate, { days: 6 - i })
        );

        dates.push(dateString);

        weekNoteIdsByDate[dateString] = notesByDate[dateString] ?? [];
      }

      const weekNotesByDate: NoteDocsByDate = {};

      const db = getFirestore();

      await Promise.all(
        dates.map((date) => {
          const noteRefs = weekNoteIdsByDate[date]
            .filter((nid) => !deletedNotes.includes(nid))
            .map((nid) => doc(db, FirestoreCollection.Notes, nid));

          return Promise.all(noteRefs.map((nr) => getDoc(nr))).then(
            (noteDocs) => {
              weekNotesByDate[date] = noteDocs.flatMap((nd) => {
                if (nd.exists()) {
                  const noteDoc = nd.data() as FirestoreNoteDocument;

                  return {
                    ...noteDoc,
                    noteId: nd.id,
                    eventTime: noteDoc.eventTime ?? undefined,
                    sideEffectId: noteDoc.sideEffectId ?? undefined,
                    routineCategoryId: noteDoc.routineCategoryId ?? undefined,
                  } as Note;
                } else {
                  return [];
                }
              });
            }
          );
        })
      );

      setNotesForWeek(weekNotesByDate);

      setLoading(false);
    };

    getNotesForWeek();
  }, [selectedChild, currentDate, noteDates, notesByDate]);

  return (
    <NotesContextProvider
      value={{
        noteDates,
        noteDateCounts,
        notesByDate,
        notesForWeek,
        currentDate,
        setCurrentDate,
        setNoteDates,
        setNotesByDate,
        setDeletedNote,
        loading,
      }}
    >
      {props.children}
    </NotesContextProvider>
  );
};

export { useNotesContext, NotesProvider };
