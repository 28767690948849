import { useCallback } from "react";
import { HookResult } from "@neurosolutionsgroup/models";
import {
  FTUEAssets,
  FTUEFlow,
  ProgressState,
  ftueStartOfFTUEDialog,
} from "models";
import { FirstRoutineProgress } from "flows/FirstRoutine";
import { ValidationProgress } from "flows/Validation";
import { ObservationProgress } from "flows/Observation";
import { DashboardProgress } from "flows/Dashboard";
import { DashboardPrescriptionsProgress } from "flows/DashboardPrescription";
import { PrescriptionsProgress } from "flows/Prescription";
import { JournalProgress } from "flows/Journal";
import { useFTUEContext } from "./FTUEContext";
import {
  FTUEJournalComplete,
  FTUEObjectivesComplete,
  FTUEPrescriptionComplete,
  useAnalytics,
} from "@neurosolutionsgroup/analytics";

export interface useFTUESelectors {
  progress: ProgressState;
  blockFTUE: boolean;
  template?: string;
  ftueRunning: boolean;
  firstPageFTUEIsRunning: boolean;
  assets: FTUEAssets;
  shouldShowIntroFTUE: boolean;
}

export interface useFTUEActions {
  canDisplayFlow: (flow: FTUEFlow) => boolean;
  getCompletedDate: (finishedId: string) => number | undefined;
  hasFinishedFlow: (flow: FTUEFlow) => boolean;
  setFirstRoutineProgress: (
    value: FirstRoutineProgress,
    template?: string,
    awaitUpdate?: boolean,
    abTest?: string,
    quit?: boolean
  ) => Promise<void>;
  setValidationProgress: (value: ValidationProgress) => void;
  setObservationProgress: (value: ObservationProgress) => void;
  setDashboardProgress: (value: DashboardProgress) => void;
  setDashboardPrescriptionsProgress: (
    value: DashboardPrescriptionsProgress
  ) => void;
  setPrescriptionsProgress: (
    value: PrescriptionsProgress,
    quit?: boolean
  ) => void;
  setJournalProgress: (value: JournalProgress, quit?: boolean) => void;
  onJournalAddClicked: VoidFunction;
  onPrescriptionAddClicked: VoidFunction;
  onRoutineAddClicked: () => boolean;
  onTaskAddClicked: VoidFunction;
  requestRoutineFTUE: VoidFunction;
  onStartOfFTUEClosed: VoidFunction;
  onChallengeAdded: VoidFunction;
}

export type UseFTUEResult = HookResult<useFTUESelectors, useFTUEActions>;

const useFTUE = (): UseFTUEResult => {
  const {
    blockFTUE,
    progressState,
    setProgressState,
    template,
    initialSetupFinished,
    ftueRunning,
    firstPageFTUEIsRunning,
    ftueRecords,
    updateFTUERecord,
    assets,
    shouldShowIntroFTUE,
    setShouldShowIntroFTUE,
  } = useFTUEContext();

  const { handleEvent } = useAnalytics();

  const onChallengeAdded = () => {
    if (
      !progressState[FTUEFlow.FirstRoutine].complete &&
      progressState[FTUEFlow.FirstRoutine].progress ===
        FirstRoutineProgress.TemplateChosen
    ) {
      setFirstRoutineProgressProxy(FirstRoutineProgress.RoutineDisplay);
    }
  };

  const onJournalAddClicked = () => {
    if (
      !progressState[FTUEFlow.Journal].complete &&
      progressState[FTUEFlow.Journal].progress === JournalProgress.Later
    ) {
      setJournalProgressProxy(JournalProgress.AddNote);
    }
  };

  const onPrescriptionAddClicked = () => {
    if (
      !progressState[FTUEFlow.Prescriptions].complete &&
      progressState[FTUEFlow.Prescriptions].progress ===
        PrescriptionsProgress.Later
    ) {
      setPrescriptionsProgressProxy(PrescriptionsProgress.Intro);
    }
  };

  const onTaskAddClicked = () => {
    if (
      !progressState[FTUEFlow.FirstRoutine].complete &&
      progressState[FTUEFlow.FirstRoutine].progress ===
        FirstRoutineProgress.TaskOrder
    ) {
      setFirstRoutineProgressProxy(FirstRoutineProgress.AddTask);
    }
  };

  /**
   * Check FTUE action necessary on routine creation.
   * @returns Whether the caller should manually continue creation or if FTUE takes control.
   */
  const onRoutineAddClicked = (): boolean => {
    if (
      !progressState[FTUEFlow.FirstRoutine].complete &&
      (progressState[FTUEFlow.FirstRoutine].progress ===
        FirstRoutineProgress.Later ||
        progressState[FTUEFlow.FirstRoutine].progress ===
          FirstRoutineProgress.None)
    ) {
      setFirstRoutineProgressProxy(FirstRoutineProgress.Intro);
      return false;
    }

    return true;
  };

  const getCompletedDate = (finishedId: string): number | undefined => {
    const finishedFlag = ftueRecords.find((r) => r.id === finishedId);

    return finishedFlag?.lastSeen;
  };

  // #region Set Progress.

  const setFirstRoutineProgressProxy = async (
    progress: FirstRoutineProgress,
    template?: string,
    awaitUpdate = false,
    abTest?: string,
    quit = false
  ): Promise<void> => {
    if (progress === FirstRoutineProgress.RoutineFTUEFinished) {
      const event: FTUEObjectivesComplete = {
        name: "FTUE - Objectives Complete",
        eventProperties: {
          Skipped: quit,
        },
      };

      handleEvent(event);
    }

    if (awaitUpdate) {
      if (template || abTest) {
        const extraData: Record<string, string | number | boolean> = {};

        if (template) {
          extraData.template = template;
        }

        if (abTest) {
          extraData.abTest = abTest;
        }
        await updateFTUERecord(progress.toString(), extraData);
      } else {
        await updateFTUERecord(progress.toString());
      }

      setProgressState((current) => ({
        ...current,
        [FTUEFlow.FirstRoutine]: {
          ...current[FTUEFlow.FirstRoutine],
          progress,
          complete: progress === FirstRoutineProgress.RoutineFTUEFinished,
        },
      }));

      return Promise.resolve();
    } else {
      setProgressState((current) => ({
        ...current,
        [FTUEFlow.FirstRoutine]: {
          ...current[FTUEFlow.FirstRoutine],
          progress,
          complete: progress === FirstRoutineProgress.RoutineFTUEFinished,
        },
      }));

      if (template || abTest) {
        const extraData: Record<string, string | number | boolean> = {};

        if (template) {
          extraData.template = template;
        }

        if (abTest) {
          extraData.abTest = abTest;
        }
        await updateFTUERecord(progress.toString(), extraData);
      } else {
        await updateFTUERecord(progress.toString());
      }

      return Promise.resolve();
    }
  };

  const setValidationProgressProxy = (progress: ValidationProgress) => {
    setProgressState((current) => ({
      ...current,
      [FTUEFlow.Validation]: {
        ...current[FTUEFlow.Validation],
        progress,
        complete: progress === ValidationProgress.ValidationFTUEFinished,
      },
    }));

    updateFTUERecord(progress.toString());
  };

  const setObservationProgressProxy = (progress: ObservationProgress) => {
    setProgressState((current) => ({
      ...current,
      [FTUEFlow.Observation]: {
        ...current[FTUEFlow.Observation],
        progress,
        complete: progress === ObservationProgress.ObservationsFTUEFinished,
      },
    }));

    updateFTUERecord(progress.toString());
  };

  const setDashboardProgressProxy = (progress: DashboardProgress) => {
    setProgressState((current) => ({
      ...current,
      [FTUEFlow.Dashboard]: {
        ...current[FTUEFlow.Dashboard],
        progress,
        complete: progress === DashboardProgress.DashboardFTUEFinished,
      },
    }));

    updateFTUERecord(progress.toString());
  };

  const setDashboardPrescriptionsProgressProxy = (
    progress: DashboardPrescriptionsProgress
  ) => {
    setProgressState((current) => ({
      ...current,
      [FTUEFlow.DashboardPrescriptions]: {
        ...current[FTUEFlow.DashboardPrescriptions],
        progress,
        complete:
          progress ===
          DashboardPrescriptionsProgress.DashboardPrescriptionsFTUEFinished,
      },
    }));

    updateFTUERecord(progress.toString());
  };

  const setPrescriptionsProgressProxy = (
    progress: PrescriptionsProgress,
    quit = false
  ) => {
    setProgressState((current) => ({
      ...current,
      [FTUEFlow.Prescriptions]: {
        ...current[FTUEFlow.Prescriptions],
        progress,
        complete: progress === PrescriptionsProgress.Finished,
      },
    }));

    if (progress === PrescriptionsProgress.Finished) {
      const event: FTUEPrescriptionComplete = {
        name: "FTUE - Prescription Complete",
        eventProperties: {
          Skipped: quit,
        },
      };

      handleEvent(event);
    }

    updateFTUERecord(progress);
  };

  const setJournalProgressProxy = (progress: JournalProgress, quit = false) => {
    setProgressState((current) => ({
      ...current,
      [FTUEFlow.Journal]: {
        ...current[FTUEFlow.Journal],
        progress,
        complete: progress === JournalProgress.JournalFTUEFinished,
      },
    }));

    if (progress === JournalProgress.JournalFTUEFinished) {
      const event: FTUEJournalComplete = {
        name: "FTUE - Journal Complete",
        eventProperties: {
          Skipped: quit,
        },
      };

      handleEvent(event);
    }

    updateFTUERecord(progress.toString());
  };

  // #endregion

  const canDisplayFlow = useCallback(
    (flow: FTUEFlow): boolean => {
      if (!initialSetupFinished) {
        return false;
      }

      // Don't show validation and observation FTUEs at the same time.
      if (
        (!progressState[FTUEFlow.Validation].complete &&
          progressState[FTUEFlow.Validation].criteriaMet) ||
        progressState[FTUEFlow.Validation].debug
      ) {
        if (flow === FTUEFlow.Observation) {
          return false;
        }
      }

      // Remaining FTUEs depend on location and should not conflict.
      return (
        progressState[flow].debug ||
        (!progressState[flow].complete && progressState[flow].criteriaMet)
      );
    },
    [progressState, initialSetupFinished]
  );

  const hasFinishedFlow = (flow: FTUEFlow): boolean => {
    return progressState[flow].complete;
  };

  const requestRoutineFTUE = () => {
    setProgressState((current) => ({
      ...current,
      [FTUEFlow.FirstRoutine]: {
        progress: FirstRoutineProgress.None,
        complete: false,
        criteriaMet: true,
        debug: false,
      },
    }));
  };

  const onStartOfFTUEClosed = () => {
    setShouldShowIntroFTUE(false);
    updateFTUERecord(ftueStartOfFTUEDialog);
  };

  return {
    selectors: {
      progress: progressState,
      blockFTUE,
      template,
      ftueRunning,
      firstPageFTUEIsRunning,
      assets,
      shouldShowIntroFTUE,
    },
    actions: {
      getCompletedDate: getCompletedDate,
      setFirstRoutineProgress: setFirstRoutineProgressProxy,
      setValidationProgress: setValidationProgressProxy,
      setObservationProgress: setObservationProgressProxy,
      setDashboardProgress: setDashboardProgressProxy,
      setDashboardPrescriptionsProgress: setDashboardPrescriptionsProgressProxy,
      setPrescriptionsProgress: setPrescriptionsProgressProxy,
      setJournalProgress: setJournalProgressProxy,
      onJournalAddClicked,
      onPrescriptionAddClicked,
      onRoutineAddClicked,
      onTaskAddClicked,
      canDisplayFlow,
      hasFinishedFlow,
      requestRoutineFTUE,
      onStartOfFTUEClosed,
      onChallengeAdded,
    },
  };
};

export default useFTUE;
