import FaceIcon, { FaceState } from "./FaceIcon";
import FeedbackIcon from "./FeedbackIcon";
import { useTranslation } from "react-i18next";
import React from "react";
import { Box, Grid, Slider, Typography } from "@mui/material";
import { Mark } from "@mui/base";

interface FrequencyFormProps {
  frequency: number | undefined;
  setFrequncy: (newValue: number) => void;
}

const FrequencyForm = ({
  frequency,
  setFrequncy,
}: FrequencyFormProps): JSX.Element => {
  const { t } = useTranslation();

  const marks = (): Mark[] => {
    return Array.from({ length: 8 }, (_, i) => ({ value: i }));
  };

  const iconState = (): FaceState => {
    if (frequency !== undefined && frequency <= 2) {
      return FaceState.Happy;
    } else if (frequency !== undefined && frequency >= 5) {
      return FaceState.Sad;
    } else {
      return FaceState.Neutral;
    }
  };

  return (
    <Grid container direction="column" alignItems="stretch" spacing={2}>
      <Grid item display="flex" justifyContent="center">
        <FeedbackIcon shape="round" icon={<FaceIcon state={iconState()} />} />
      </Grid>
      <Grid item>
        <Box px={2} width="auto">
          <Slider
            marks={marks()}
            min={marks()[0].value}
            max={marks()[marks().length - 1].value}
            step={null}
            value={frequency}
            onChange={(_, v) => setFrequncy(v as number)}
            sx={{
              color: "#fff",
            }}
          />
        </Box>
      </Grid>
      <Grid item display="flex" justifyContent="center">
        {frequency !== undefined ? (
          <Typography color="#fff">
            {frequency} {t("general.day", { count: frequency })}
          </Typography>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default FrequencyForm;
