import { Box, Grid, Typography } from "@mui/material";
import { Template } from "@neurosolutionsgroup/models";
import { templateLoc } from "@neurosolutionsgroup/localization";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FullScreenFTUEProps } from "components";
import TemplateChoice from "./TemplateChoice";
import {
  BackButton,
  calculateStickyButtonPagePadding,
  StickyButtons,
} from "@neurosolutionsgroup/components";
import { FTUEAssets } from "models";

interface FTUETemplateScreenProps extends FullScreenFTUEProps {
  allowChallengeCreation: boolean;
  lockedChallenge?: boolean;
  lockedTemplates?: Template[];
  onPremiumLockChallengeClick: () => void;
  onPremiumLockTemplateClick: (template: Template) => void;
  onTemplateChoice: (
    challengeSelected: boolean,
    template: Template | undefined
  ) => void;
  onBack: VoidFunction;
  assets: FTUEAssets;
}

export const TemplateScreen = ({
  allowChallengeCreation,
  language,
  lockedChallenge = false,
  lockedTemplates = [],
  onPremiumLockChallengeClick,
  onPremiumLockTemplateClick,
  onTemplateChoice,
  onBack,
  assets,
  safeAreas,
}: FTUETemplateScreenProps): JSX.Element => {
  const { t } = useTranslation();

  const [template, setTemplate] = useState<Template>();
  const [challengeSelected, setChallengeSelected] = useState<boolean>(false);
  const [showChallengeError, setShowChallengeError] = useState<boolean>(false);

  const nextDisabled = !template && !challengeSelected;

  const onTemplateClick = (template: Template) => {
    setShowChallengeError(false);
    setTemplate(template);
    setChallengeSelected(false);
  };

  const onNext = () => {
    onTemplateChoice(challengeSelected, template);
  };

  return (
    <Box
      sx={{
        paddingTop: safeAreas.top,
        paddingBottom: calculateStickyButtonPagePadding(safeAreas),
      }}
    >
      <Grid container flexGrow={1} direction="column" padding={1} spacing={1}>
        <Grid item>
          <BackButton onClick={onBack} color="#fff" />
        </Grid>
        <Grid item marginTop="auto" marginBottom={1}>
          <Typography textAlign="center" color="#fff" fontWeight="bold">
            {t("ftue.firstRoutine.template.select")}
          </Typography>
        </Grid>
        <Grid item marginBottom="auto">
          <Box>
            <Grid container spacing={1}>
              <TemplateChoice
                text={t("routine.challenge.create")}
                imageSrc={assets.challengeIcon}
                onClick={() => {
                  if (allowChallengeCreation) {
                    setChallengeSelected(true);
                    setTemplate(undefined);
                  } else {
                    setShowChallengeError(true);
                  }
                }}
                active={challengeSelected}
                onPremiumLockClick={onPremiumLockChallengeClick}
                premiumLock={lockedChallenge}
              />
              {showChallengeError ? (
                <Typography
                  color="#fff"
                  fontWeight={600}
                  fontSize="0.8rem"
                  paddingX={2}
                  mt={1}
                >
                  {t("routine.challenge.creationError")}
                </Typography>
              ) : null}
              <TemplateChoice
                data-cy="morning-template"
                text={templateLoc[language][Template.Morning]}
                imageSrc={assets.routineTemplateIcons[1]}
                onClick={() => {
                  onTemplateClick(Template.Morning);
                }}
                active={template === Template.Morning}
                onPremiumLockClick={() =>
                  onPremiumLockTemplateClick(Template.Morning)
                }
                premiumLock={lockedTemplates.includes(Template.Morning)}
              />
              <TemplateChoice
                data-cy="midday-template"
                text={templateLoc[language][Template.Midday]}
                imageSrc={assets.routineTemplateIcons[2]}
                onClick={() => {
                  onTemplateClick(Template.Midday);
                }}
                active={template === Template.Midday}
                onPremiumLockClick={() =>
                  onPremiumLockTemplateClick(Template.Midday)
                }
                premiumLock={lockedTemplates.includes(Template.Midday)}
              />
              <TemplateChoice
                data-cy="afternoon-template"
                text={templateLoc[language][Template.Afternoon]}
                imageSrc={assets.routineTemplateIcons[3]}
                onClick={() => {
                  onTemplateClick(Template.Afternoon);
                }}
                active={template === Template.Afternoon}
                onPremiumLockClick={() =>
                  onPremiumLockTemplateClick(Template.Afternoon)
                }
                premiumLock={lockedTemplates.includes(Template.Afternoon)}
              />
              <TemplateChoice
                data-cy="evening-template"
                text={templateLoc[language][Template.Evening]}
                imageSrc={assets.routineTemplateIcons[4]}
                onClick={() => {
                  onTemplateClick(Template.Evening);
                }}
                active={template === Template.Evening}
                onPremiumLockClick={() =>
                  onPremiumLockTemplateClick(Template.Evening)
                }
                premiumLock={lockedTemplates.includes(Template.Evening)}
              />
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <StickyButtons
        onConfirm={onNext}
        confirmText={t("general.actions.next")}
        data-cy="submit-template"
        disabled={nextDisabled}
        safeAreas={safeAreas}
      />
    </Box>
  );
};

export default TemplateScreen;
